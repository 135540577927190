.form-header {
  margin: 1.5rem auto;
  h4 {
    margin: 0;
  }
}

.form-container {
  text-align: left;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  margin: 0 auto; /* Center the form */
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0rem 1rem;
}

.reset-password {
  color: teal;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.required {
  color: red;
}
